import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'

const TermsPrivacy = ({ data: { page } }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <section
        className="hero is-large is-page-hero"
        style={{
          backgroundImage: `url(${page.backgroundImage.fixed.src})`
        }}
      >
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-white has-text-weight-normal">
              {page.title}
            </h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content">
            {ReactHtmlParser(page.contentNode.childMarkdownRemark.html)}
          </div>
        </div>
      </section>
    </Layout>
  )
}

TermsPrivacy.propTypes = {
  data: PropTypes.object.isRequired
}

export default TermsPrivacy

export const query = graphql`
  query TermsPrivacyQuery {
    page: datoCmsTermsPrivacy {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      backgroundImage {
        fixed(
          width: 1920
          imgixParams: {
            auto: "compress"
            fm: "pjpg"
            crop: "entropy"
            bri: -15
          }
        ) {
          src
        }
      }
    }
  }
`
